import React, { useEffect, useReducer } from 'react';
import './App.css';
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import Widgets from './components/Widgets/Widgets'
import Info from './components/Info/Info';
import Voting from './components/Voting/Voting';
import LoginGuide from './components/LoginGuide/LoginGuide';
import Login from './components/Login/Login';
import LoginManager from './components/Login/LoginManager';
import { loggedIn, updateProfile } from './services/Login';
import Imprint from './components/Imprint/Imprint';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Constitution from './components/constitution/constitution';
import { ScrollPositioner } from './components/ScrollPositioner/ScrollPositioner';
import Bank from './components/Bank/Bank';
import CreateTransactionData from './components/Bank/CreateTransaction';

export default function App() {
  const forceUpdate = useReducer(x => x + 1, 0)[1]

  useEffect(() => {
    updateProfile().then(() => {
      if(loggedIn()) {
        forceUpdate()
      }
    })
  }, [forceUpdate])

  return (
    <div className='App'>
      <LoginManager />
      <ScrollPositioner resetPaths={["/info", "/voting"]}/>
      <div className='AppContent'>
        <Routes>
          <Route path="/" Component={Widgets} />
          <Route path="/info/*" Component={Info} />
          <Route path="/voting" Component={Voting} />
          <Route path="/imprint" Component={Imprint} />
          <Route path="/privacyPolicy" Component={PrivacyPolicy} />
          <Route path="/constitution" element={<Constitution />} />
          <Route path="/login" Component={Login} />
          <Route path="/login/guide" Component={LoginGuide} />
          <Route path="/bank" Component={Bank} />
          <Route path="/bank/createTransaction" element={<CreateTransactionData isRequest={false}/>}/>
          <Route path="/bank/createTransactionRequest" element={<CreateTransactionData isRequest={true}/>}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <div className="FooterContainer">
        <div className="Footer">
          <div className="FooterImportant">
            <p>Wichtiges</p>
            <ul className="FooterImportantList">
              <li key="Verfassungsentwurf">
                <Link className="inline-link" to="/info/politics/constitution">Vefassung</Link>
              </li>
              <li key="Parteigründung">
                <Link className="inline-link" to="/info/politics/partyFounding">Parteigründung</Link>
              </li>
            </ul>
          </div>
          <div className="FooterLocation">
            <p>Parkstr. 1<br/>24534 Neumünster</p>
            <p>Tel. 04321 / 942 4260<br/>Fax 04321 / 942 4259</p>
            <p><a className="inline-link" href="https://www.klaus-groth-schule.de/kontakt/anfrage/?to=297">E-Mail schreiben</a><br/><a className="inline-link" href="https://www.klaus-groth-schule.de">www.klaus-groth-schule.de</a></p>
          </div>
          <div className="FooterLegal">
            <div>
              <Link className="inline-link" to="/imprint">Impressum</Link>
            </div>
            <div>
              <Link className="inline-link" to="/privacyPolicy">Datenschutzerklärung</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}