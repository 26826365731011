import React from "react";
import "./Bank.css";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import {
  currentProfile,
  requireLoggedIn,
  rerouteToLogin,
  updateProfile,
} from "../../services/Login";
import {
  Transaction,
  TransactionData,
  TransactionRequest,
  approveTransactionRequest,
  denyTransactionRequest,
  listTransactionRequests,
  listTransactions,
} from "../../services/Bank";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

interface TransactionRequestConfirmation {
  data: TransactionData;
  isApproval: boolean;
  confirmationFailedReason?: string;
}

function confirmationRequestRequiresPassword(
  request: TransactionRequestConfirmation,
): boolean {
  return (
    request.isApproval && request.data.from !== currentProfile!!.dbusername
  );
}

interface BankState {
  balance?: number;
  transactions?: Transaction[];
  transactionRequests?: TransactionRequest[];
  transactionRequestConfirmation?: TransactionRequestConfirmation;
  loading: boolean;
}

export default class Bank extends React.Component<{}, BankState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  updateTransactions() {
    this.setState({ ...this.state, loading: true });
    updateProfile().then(() => {
      this.setState({ ...this.state, balance: currentProfile?.bankBalance });
      listTransactions().then((transactions) => {
        this.setState({ ...this.state, transactions });
        listTransactionRequests().then((transactionRequests) => {
          this.setState({
            ...this.state,
            transactionRequests,
            loading: false,
          });
        });
      });
    });
  }

  componentDidMount() {
    if (!requireLoggedIn()) return;
    this.updateTransactions();
  }

  groupTransactionsByDate(transactions: Transaction[]) {
    const grouped: { [date: string]: Transaction[] } = {};
    transactions.forEach((transaction) => {
      const date = new Date(transaction.time * 1000).toLocaleDateString(
        undefined,
        {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        },
      );
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(transaction);
    });
    return grouped;
  }

  renderTransactionData(
    data: TransactionData,
    showRequestApprovalAndDenyButton: boolean = false,
    isLastTransaction: boolean = false
  ) {
    const isIncoming = data.to === currentProfile!!.dbusername;
    return (
      <li key={data.id}>
        <div className="BankTransactionData">
          <FontAwesomeIcon
            icon={isIncoming ? faArrowDown : faArrowUp}
            className={isIncoming ? "BankIncomingArrow" : "BankOutgoingArrow"}
            style={{ color: isIncoming ? "lime" : "red", marginRight: "0.5em" }}
          />
          <span
            className={
              isIncoming
                ? "BankTransactionAmountPos"
                : "BankTransactionAmountNeg"
            }
          >
            {isIncoming || "-"}
            {data.amount}
          </span>
          <span
            className={
              isIncoming ? "BankTransactionPayer" : "BankTransactionRecipient"
            }
          >
            {isIncoming ? `Von: ${data.from}` : `An: ${data.to}`}
          </span>
          <span className="BankTransactionTime">
            {new Date(data.time * 1000).toLocaleTimeString(undefined, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
  
          <div className="BankTransactionDescription">{data.description}</div>
  
          {showRequestApprovalAndDenyButton && (
            <>
              <button
                className="BankApproveTransactionRequest"
                title="Approve"
                type="button"
                onClick={() => this.onApproveRequest(data)}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                className="BankDenyTransactionRequest"
                title="Deny"
                type="button"
                onClick={() => this.onDenyRequest(data)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </>
          )}
        </div>
        {/* Only render the <hr> if it's not the last transaction */}
        {!isLastTransaction && <hr className="BankTransactionDivider" />}
      </li>
    );
  }
  
  
  

  onApproveRequest(request: TransactionRequest) {
    this.setState({
      ...this.state,
      transactionRequestConfirmation: { data: request, isApproval: true },
    });
  }

  onDenyRequest(request: TransactionRequest) {
    this.setState({
      ...this.state,
      transactionRequestConfirmation: { data: request, isApproval: false },
    });
  }

  onConfirmApproveRequest(requestId: number, password?: string) {
    approveTransactionRequest(requestId, password)
      .then(
        (_) => ({ transactionRequestConfirmation: undefined }),
        (error) => {
          let errorMessage: string = "Unbekannter Fehler";
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data === "Insufficient funds"
          ) {
            errorMessage = "Unzureichend Geld";
          } else if (error.response && error.response.status === 401) {
            if (error.response.data === "du bist nicht angemeldet") {
              rerouteToLogin();
            } else if (
              error.response.data === "Unauthorized to approve this transaction"
            ) {
              errorMessage = "Falsches Passwort";
            }
          }
          return {
            transactionRequestConfirmation: {
              ...this.state.transactionRequestConfirmation!!,
              confirmationFailedReason: errorMessage,
            },
          };
        },
      )
      .then((newState) => this.setState({ ...this.state, ...newState }))
      .then(() => {
        this.updateTransactions();
      });
  }

  onConfirmDenyRequest(requestId: number) {
    denyTransactionRequest(requestId).then(() => {
      this.updateTransactions();
    });
  }

  render() {
    const groupedTransactions =
      this.state && this.state.transactions
        ? this.groupTransactionsByDate(this.state.transactions)
        : {};
  
    return (
      <div className="Bank">
        <Header goBackLink="/" />
        <div className="BankContent">
          <div className="BankBalance">
            Kontostand: {(this.state?.balance || 0)}
          </div>
          <div className="BankCreateTransactionDataLinkList">
            <div className="BankCreateTransactionDataLink">
              <Link to="createTransactionRequest">
                Überweisungsanfrage erstellen
              </Link>
            </div>
            <div className="BankCreateTransactionDataLink">
              <Link to="createTransaction">Überweisung erstellen</Link>
            </div>
          </div>
          <div className="BankTransactionDataList">
            <h2>Überweisungsanfragen:</h2>
            <ul className="BankTransactionRequests">
              {this.state &&
                (this.state.transactionRequests?.length === 0 ? (
                  <span key={-1}>Du hast noch keine Überweisungsanfragen</span>
                ) : (
                  this.state.transactionRequests?.map((request) =>
                    this.renderTransactionData(request, true),
                  )
                ))}
            </ul>
          </div>
          <div className="BankTransactionDataList">
            <h2>Überweisungen:</h2>
            {this.state &&
              (this.state.transactions?.length === 0 ? (
                <span key={-1}>Du hast noch keine Überweisungen</span>
              ) : (
                Object.keys(groupedTransactions).map((date) => (
                  <div key={date}>
                    <h3>{date}</h3>
                    <ul className="BankTransactions">
                      {groupedTransactions[date].map((transaction, index) =>
                        this.renderTransactionData(
                          transaction,
                          false,
                          index === groupedTransactions[date].length - 1 // Check if it's the last transaction
                        )
                      )}
                    </ul>
                  </div>
                ))
              ))}
          </div>
        </div>
        {this.state?.transactionRequestConfirmation && (
          <div className="TransactionRequestApprovalConfirmation">
            <div className="TransactionRequestApprovalConfirmationText">
              Möchtest du folgende Überweisungsanfrage wirklich{" "}
              {this.state.transactionRequestConfirmation.isApproval
                ? "bestätigen"
                : "entfernen"}
              ?
              <div className="BankTransactionDataList">
                <ul className="BankTransactionRequests">
                  {this.renderTransactionData(
                    this.state.transactionRequestConfirmation.data,
                  )}
                </ul>
              </div>
            </div>
            {confirmationRequestRequiresPassword(
              this.state.transactionRequestConfirmation,
            ) && (
              <div className="TransactionRequestApprovalPassword">
                <div>
                  <input
                    type="password"
                    placeholder="Passwort"
                    id="TransactionRequestApprovalPassword"
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="showPassword"
                    onClick={(event) => {
                      (
                        document.getElementById(
                          "TransactionRequestApprovalPassword",
                        ) as HTMLInputElement
                      ).type = (event.target as HTMLInputElement).checked
                        ? "text"
                        : "password";
                    }}
                  />
                  <label htmlFor="showPassword">Passwort anzeigen</label>
                </div>
                <div>
                  {this.state.transactionRequestConfirmation
                    .confirmationFailedReason && (
                    <span className="TransactionRequestConfirmationFailedReason">
                      {
                        this.state.transactionRequestConfirmation
                          .confirmationFailedReason
                      }
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="TransactionRequestApprovalConfirmationButtons">
              <button
                onClick={() =>
                  this.setState({
                    ...this.state,
                    transactionRequestConfirmation: undefined,
                  })
                }
              >
                Nein
              </button>
              <button
                onClick={() => {
                  if (this.state.transactionRequestConfirmation!!.isApproval) {
                    this.onConfirmApproveRequest(
                      this.state.transactionRequestConfirmation!!.data.id,
                      confirmationRequestRequiresPassword(
                        this.state.transactionRequestConfirmation!!,
                      )
                        ? (
                            document.getElementById(
                              "TransactionRequestApprovalPassword",
                            ) as HTMLInputElement
                          ).value
                        : undefined,
                    );
                  } else {
                    this.onConfirmDenyRequest(
                      this.state.transactionRequestConfirmation!!.data.id,
                    );
                    this.setState({
                      ...this.state,
                      transactionRequestConfirmation: undefined,
                    });
                  }
                }}
              >
                Ja
              </button>
            </div>
          </div>
        )}
        {this.state.loading && (
          <div className="Loader">
            <div className="Spinner"></div>
          </div>
        )}
      </div>
    );
  }
}
