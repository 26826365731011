import React, { useEffect, useRef, useState } from 'react'
import { listUsernames, loggedIn } from '../../services/Login'
import './UsernameInput.css'

export default function AutoComplete() {
    const [search, setSearch] = useState('')
    const [input, setInput] = useState('')
    const [autocompletes, setAutocompletes] = useState<string[] | undefined>()
    const [scheduledFocusLoss, setScheduledFocusLoss] = useState(false)
    const [focus, setFocus] = useState(false)
    const searchInput = useRef<HTMLInputElement>(null);
    const autocompleteList = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(scheduledFocusLoss) {
            setFocus(false)
        }
    }, [scheduledFocusLoss])

    useEffect(() => {
        if(search === '') {
            setAutocompletes(undefined);
            return
        }
        if(!loggedIn()) return
        listUsernames(search).then(setAutocompletes)
    }, [search])

    function onKeyDown(event: React.KeyboardEvent) {
        if(event.key !== "ArrowDown" && event.key !== "ArrowUp") return
        const focused = document.activeElement;
        const suggestions = autocompleteList.current?.children
        if(!suggestions || suggestions.length === 0) return
        event.preventDefault()
        if(focused === searchInput.current) {
            (suggestions[event.key === "ArrowDown" ? 0 : (suggestions.length - 1)] as HTMLElement).focus()
            return
        }
        const index = Array.from(suggestions).indexOf(focused as HTMLElement)
        if(index === -1) return
        const nextIndex = (index + (event.key === "ArrowDown" ? 1 : -1))
        if(nextIndex < 0 || nextIndex >= suggestions.length) {
            searchInput.current?.focus()
            return
        }
        (suggestions[nextIndex] as HTMLElement).focus()
    }

    return (
        <div
            className='UsernameInput'
            onKeyDown={onKeyDown}
            onFocus={() => {
                setScheduledFocusLoss(false)
                setFocus(true)
            }}
            onBlur={() => setScheduledFocusLoss(true)}
        >
            <div className='UsernameInputContainer'>
                <input
                    placeholder='Benutzername'
                    name='username'
                    type='text'
                    id='username'
                    className='textInput' ref={searchInput}
                    value={input}
                    onChange={event => {
                        setInput(event.target.value)
                        setSearch(event.target.value)
                    }}
                    autoComplete='off'
                    required={true}
                />
            </div>
            {
                focus && autocompletes && autocompletes.length !== 0 && <div className='UsernameInputAutoCompleteList' ref={autocompleteList}>
                    {autocompletes.map((autocomplete, index) => (
                        <div
                            className="UsernameInputAutoCompleteListEntry"
                            onClick={() => setInput(autocomplete)}
                            onKeyDown={event => {
                                if(event.key === "Enter") {
                                    setInput(autocomplete)
                                    event.preventDefault()
                                }
                            }}
                            key={autocomplete}
                            tabIndex={0}
                        >
                            {autocomplete}
                        </div>)
                    )}
                </div>
            }
        </div>
    )
}