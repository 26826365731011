import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { loggedIn, logout } from '../services/Login';

interface HeaderProps {
    goBackLink?: string;
}

export default function Header(props: HeaderProps) {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                closeSidebar();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="App-header">
             <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <button onClick={closeSidebar} className="close-button">✖</button>
                {
                    loggedIn()
                        ? <Link className="sidebar-link" to='/' onClick={logout}>Abmelden</Link>
                        : <Link className="sidebar-link" to="/login">Anmelden</Link>
                }
                <Link className="sidebar-link" to="/info/politics/constitution">Verfassung</Link>
            </div>
            {
                props.goBackLink && (<Link to={props.goBackLink}>
                    <svg className="back-button" viewBox='0 0 10 10'>
                        <path fillRule='evenodd' clip-rule="evenodd" d="M 0 5 L5 0 L5 3 L 9 3 L 9 7 L 5 7 L 5 10 Z" fill="white"/>
                    </svg>
                </Link>)
            }
            <Link className="header-flag-container" to="/">
                <img src="/images/placeholderFlag.png" alt="Deutschlandflagge mit SV Logo" className="header-flag" />
            </Link>
            <div className='Title'>Klaus macht Staat</div>
            <button onClick={() => setSidebarOpen(true)} className="menu-button">☰</button>
        </header>
    );
}
