import React, { useEffect, useState } from 'react';
import './constitution.css';
import Header from '../../Header/Header';
import mammoth from 'mammoth';

export default function App() {
  const [docxContent, setDocxContent] = useState('');

  useEffect(() => {
    fetch('/Politik-Text Website.docx')
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      })
      .then((blob) => {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const target = event.target as FileReader;
          const arrayBuffer = target.result;
          if (arrayBuffer instanceof ArrayBuffer) {
            mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
              .then((result) => {
                setDocxContent(result.value);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        reader.readAsArrayBuffer(blob);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  return (
    <div className="Constitution">
        <Header />
        <div className='doc'>
        <div dangerouslySetInnerHTML={{ __html: docxContent }}></div>
        </div>
    </div>
  );
}
