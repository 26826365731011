import React from 'react';
import './Widgets.css';
import { Link } from 'react-router-dom';
import Header from '../../Header/Header';
import { currentProfile } from '../../services/Login';

export default class App extends React.Component<{}, {showModal: boolean}> {
  constructor(props: {}) {
    super(props);
    this.state = { showModal: false };
  }

  handleButtonClick = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className="Widgets">
        <Header />
        <div className='site-intro'>
            Dies ist die Website für das Schule-als-Staat Projekt der KGS!<br/>
            Hier findet ihr alle wichtigen Informationen, das Bezahlsystem und die Abstimmungen zur Staatsidentität
        </div>
        <ul className="button-grid">
          <li>
            <Link to="/info">
              <button className="grid-button">
                <svg className="widget-icon" height="70%" viewBox="0 0 20 20">
                  <rect x="4.5" y="18" width="10" height="2.5"></rect>
                  <rect x="8.5" y="7" width="2.5" height="12"></rect>
                  <rect x="5" y="7" width="4.5" height="2"></rect>
                  <circle cx="9.75" cy="4" r="1.5"></circle>
                </svg>
                Information
              </button>
            </Link>
          </li>
          <li>
            <Link to='/voting'>
              <button className="grid-button">
                <svg className="widget-icon" height="70%" viewBox="0 -2.5 20 20">
                  <rect x="0" y="2" width="18" height="4" fill='red'></rect>
                  <rect x="0" y="6.5" width="6" height="4" fill='blue'></rect>
                  <rect x="0" y="11" width="10" height="4" fill='green'></rect>
                  <rect x="0" y="1" width="2" height="15"></rect>
                </svg>
                Abstimmungen
              </button>
            </Link>
          </li>
          {currentProfile && currentProfile.dbusername.startsWith("test") && <li>
            <Link to='/bank'>
              <button className="grid-button">
                <svg className="widget-icon" height="70%" viewBox="-6 -6 26 26">
                  <text x="-3" y="18" fontFamily='monospace'>$</text>
                </svg>
                Bank
              </button>
            </Link>
          </li>}
          {/*<li>
            <Link to="/constitution">
              <button className="grid-button">
                Verfassung
              </button>
            </Link>
          </li>*/}
        </ul>
        {
          this.state.showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={this.closeModal}>&times;</span>
                <p>Login</p> {}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}