import React from 'react';
import './List.css';
import { Link } from "react-router-dom";
import { Page, Topic } from "./Info";
import Header from '../../Header/Header';
import { TextRange } from 'typescript';

interface ListProps {
    pages: Page[];
}

interface ListState {
    activeDropdown: string;
    filteredTopic?: Topic;
    searchQuery?: string;
}

// Define the List component as a class extending React.Component
export default class List extends React.Component<ListProps, ListState> {
    state: ListState = { activeDropdown: '' };
    toggleDropdown = (path: string) => {
        this.setState({ ...this.state, activeDropdown: this.state.activeDropdown === path ? '' : path });
    }

    // Renders the dropdown if the current page is the active one
    renderDropdown(page: Page, highlightedDescription?: TextRange) {
        if (highlightedDescription || this.state.activeDropdown === page.path) {
            return (
                <div className="dropdown-menu">
                    <div>{
                        highlightedDescription ?
                            <span>
                                {page.dropdownText.substring(0, highlightedDescription.pos)}
                                <span className='info-search-highlight'>{page.dropdownText.substring(highlightedDescription.pos, highlightedDescription.end)}</span>
                                {page.dropdownText.substring(highlightedDescription.end)}
                            </span>
                            : page.dropdownText
                    }</div> 
                    <div className='page-link-wrapper'>
                        <Link to={`/info/${page.path}`} className='page-link'>Öffnen</Link>
                    </div>
                </div>
            );
        }
        return null;
    }
    updateFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        if(event.currentTarget.value === '')
            this.setState({ ...this.state, filteredTopic: undefined })
        else
            this.setState({ ...this.state, filteredTopic: Topic.Values.find(topic => topic.displayName === event.currentTarget.value) });
    }
    updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.currentTarget.value === '')
            this.setState({ ...this.state, searchQuery: undefined })
        else
            this.setState({ ...this.state, searchQuery: event.currentTarget.value });
    }

    setFilter(topic: Topic) {
        const topicSelect = document.getElementById('info-topic-filter') as HTMLSelectElement;
        topicSelect.value = topic.displayName;
        this.setState({ ...this.state, filteredTopic: topic });
    }

    // Own implementation for letting the query omit special characters
    searchInText(text: string, query: string): TextRange | undefined {
        function isSpecialCharacter(char: string): boolean {
            return !char.match(/[a-z0-9 ]/i);
        }

        const lowerText = text.toLowerCase();
        const lowerQuery = query.toLowerCase();
        
        for(let i = 0; i < text.length; i++) {
            if(lowerText[i] === lowerQuery[0]) {
                let found = true;
                let text_offset = 1;
                let query_offset = 1;
                while(query_offset < query.length) {
                    if(i + text_offset >= text.length) {
                        found = false;
                        break;
                    }
                    if(!isSpecialCharacter(query[query_offset]) && isSpecialCharacter(text[i + text_offset])) {
                        text_offset++;
                        continue;
                    }
                    if(lowerText[i + text_offset++] !== lowerQuery[query_offset++]) {
                        found = false;
                        break;
                    }
                }
                if(found) {
                    return { pos: i, end: i + text_offset };
                }
            }
        }

        return undefined;
    }

    render() {
        return (
            <div className="List">
                <Header goBackLink='/'/>
                <div className='info-filters'>
                    <select onChange={event => this.updateFilter(event)} className='info-topic-filter' id='info-topic-filter'>
                        <option value=''>Alle Themen</option>
                        {Topic.Values.map(topic => (
                            <option key={topic.displayName} value={topic.displayName}>{topic.displayName}</option>
                        ))}
                    </select>
                    <input className='info-search-filter' onChange={event => this.updateSearch(event)} placeholder='Suche nach Artikeln...'/>
                </div>
                <ul>
                    {this.props.pages.map(page => {
                        if(this.state.filteredTopic && !page.topics?.includes(this.state.filteredTopic)) {
                            return null;
                        }
                        let title: JSX.Element;
                        let highlightedDescription: TextRange | undefined;
                        if(this.state.searchQuery) {
                            const titleSearch = this.searchInText(page.title, this.state.searchQuery);
                            if(!titleSearch) {
                                const descSearch = this.searchInText(page.dropdownText, this.state.searchQuery.toLowerCase());
                                if(!descSearch) {
                                    return null;
                                }
                                title = <span>{page.title}</span>;
                                highlightedDescription = descSearch;
                            } else {
                                title = <span>
                                    {page.title.substring(0, titleSearch.pos)}
                                    <span className='info-search-highlight'>{page.title.substring(titleSearch.pos, titleSearch.end)}</span>
                                    {page.title.substring(titleSearch.end)}
                                </span>
                            }
                        } else {
                            title = <span>{page.title}</span>;
                        }
                        return <li key={page.path}>
                            <div onClick={() => this.toggleDropdown(page.path)} className='info-dropdown-header'>
                                <img src="images/dropdown.svg" className={(highlightedDescription || this.state.activeDropdown === page.path) ? 'InfoDropdownArrowRotate' : 'InfoDropdownArrow'} alt=''/>
                                {title}
                            </div>
                            {
                                page.topics?.map(topic => (
                                    <span className='info-topic' key={topic.displayName} onClick={() => this.setFilter(topic)}>
                                        {topic.displayName}
                                    </span>
                                ))
                            }
                            {/* Render the dropdown for the page if it is the active one */}
                            {this.renderDropdown(page, highlightedDescription)}
                        </li>
                    })}
                </ul>
            </div>
        );
    }
}
