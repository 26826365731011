import React from "react";
import './PureFetchHtml.css'; 
import Header from '../Header';
import FetchHtml from "../../../FetchHtml/FetchHtml";

export default class PureFetchHtml extends React.Component<{ url: string }> {
    render() {
        return (
            <div className="PureFetchHtml">
                <Header />
                <div className="content">
                    <FetchHtml url={this.props.url}/>
                </div>
            </div>
        );
    }
}