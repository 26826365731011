import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as login from "../../services/Login"

export default function LoginManager() {
    const [rerouteToLogin, setRerouteToLogin] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    login.setRerouteToLogin(() => setRerouteToLogin(true))

    useEffect(() => {
        if(rerouteToLogin) {
            navigate(`/login?redirect=${location.pathname}`)
            setRerouteToLogin(false)
        }
    }, [rerouteToLogin, navigate, location])

    return null
}