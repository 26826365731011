import axios, { AxiosResponse } from 'axios'
import { BACKEND_LOGIN, BACKEND_LOGOUT, BACKEND_PROFILE, BACKEND_LIST_USERNAMES, BACKEND_APPROVE_TRANSACTION_REQUEST } from './Const'

export let currentProfile: Profile | null = null

export type LoginResult = { result: "authorized" } | { result: "unauthorized", reason: string }
const UNAUTHORIZED_UNKNOWN_ERROR: LoginResult = { result:"unauthorized", reason:"Unbekannter Fehler, versuche es später erneut" }
const UNAUTHORIZED_WRONG_CREDENTIALS_ERROR: LoginResult = { result:"unauthorized", reason:"Falscher Benutzername oder Passwort" }
const UNAUTHORIZED_WRONG_PASSWORD_ERROR: LoginResult = { result:"unauthorized", reason:"Falsches Passwort" }
interface Profile {
    dbusername: string,
    dbuid: number,
    iat: number,
    exp: number,
    bankBalance: number
}

export function loggedIn(): boolean {
    return currentProfile !== null
}

export function mapToLoginResult(promise: Promise<AxiosResponse<any, any>>, hasUsernameInput: boolean): Promise<LoginResult> {
    return promise.then<LoginResult>(response => {
        switch(response.status) {
            case 200:
                return { result: "authorized" }
            default:
                return UNAUTHORIZED_UNKNOWN_ERROR
        }
    }).catch(error => {
        switch(error.response?.status) {
            case 400:
            case 401:
                return hasUsernameInput ? UNAUTHORIZED_WRONG_CREDENTIALS_ERROR : UNAUTHORIZED_WRONG_PASSWORD_ERROR
            default:
                return UNAUTHORIZED_UNKNOWN_ERROR
        }
    })
}

export async function login(username: string, password: string): Promise<LoginResult> {
    return mapToLoginResult(axios.post(BACKEND_LOGIN, { username, password }, { withCredentials: true }), true).then(loginResult => {
        if(loginResult.result === "authorized")
            return updateProfile().then(() => loginResult)
        return loginResult
    })
}

export function logout() {
    currentProfile = null
    axios.post(BACKEND_LOGOUT, {}, { withCredentials: true })
}

export function updateProfile(): Promise<void> {
    return axios.post(BACKEND_PROFILE, {}, { withCredentials: true })
        .then(response => { currentProfile = response?.data as Profile || null })
}

export function listUsernames(search?: string): Promise<string[]> {
    return axios.post(BACKEND_LIST_USERNAMES, { search }, { withCredentials: true })
        .then(response => response?.data as string[])
}

export let rerouteToLogin: () => void
let prevInterceptor = -1

export function setRerouteToLogin(rerouteToLoginCallback: () => void) {
    rerouteToLogin = rerouteToLoginCallback
    if(prevInterceptor !== -1)
        axios.interceptors.response.eject(prevInterceptor)
    prevInterceptor = axios.interceptors.response.use(response => {
        return response
    }, error => {
        if(!error.response) {
            console.error("Can't reach server")
            return
        }
        if(error.response && error.response.status === 500) {
            console.error("Internal Server Error")
        }
        if(error.response && error.response.status === 404) {
            console.error("404 Error Requesting Backend")
        }
        if(error.request && error.response && error.response.status === 401) {
            if(error.request.responseURL !== BACKEND_LOGIN && error.request.responseURL !== BACKEND_LOGOUT && error.request.responseURL !== BACKEND_APPROVE_TRANSACTION_REQUEST) {
                currentProfile = null
                if(error.request.responseURL !== BACKEND_PROFILE) {
                    rerouteToLoginCallback()
                }
                return;
            }
        }
        return Promise.reject(error)
    })
}

export function requireLoggedIn(): boolean {
    if(!loggedIn()) {
        rerouteToLogin()
        return false
    }
    return true
}