import TrustedLinkReplacer from '../TrustedLinkReplacer/TrustedLinkReplacer';
import React, { useEffect, useState } from 'react';

const fetchingComponentsEmptyEvent = new CustomEvent('fetchingComponentsEmpty');
export let fetchingComponents = new Set<string>();

export default function FetchHtml(props: { url: string }) {
    const [fetchContent, setFetchContent] = useState('');

    useEffect(() => {
        fetchingComponents.add(props.url);
        fetch(props.url)
            .then((response) => {
                if (response.ok) {
                    fetchingComponents.delete(props.url);
                    response.text().then(setFetchContent);
                    return;
                }
                throw new Error('Server did not return file');
            })
            .catch((error) => {
                fetchingComponents.delete(props.url);
                setFetchContent('')
                console.error('Error fetching html:', error);
            });
    }, [props.url]);

    useEffect(() => {
        if(fetchingComponents.size === 0) {
            window.dispatchEvent(fetchingComponentsEmptyEvent);
        }
    });

    const contentElement = (
        <div className="HtmlFetch">
            <TrustedLinkReplacer>
                <div dangerouslySetInnerHTML={{ __html: fetchContent }}></div>
            </TrustedLinkReplacer>
        </div>
    );

    return contentElement
}
