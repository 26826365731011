import axios, { AxiosResponse } from "axios";
import { BACKEND_ADD_TRANSACTION, BACKEND_ADD_TRANSACTION_REQUEST, BACKEND_APPROVE_TRANSACTION_REQUEST, BACKEND_DENY_TRANSACTION_REQUEST, BACKEND_LIST_TRANSACTIONS, BACKEND_LIST_TRANSACTION_REQUESTS } from "./Const";

export interface TransactionData {
    id: number,
    to: string,
    from: string,
    amount: number,
    description?: string,
    time: number
}

export interface Transaction extends TransactionData { }
export interface TransactionRequest extends TransactionData { }

export function addTransaction(recipient: string, amount: number, description?: string): Promise<AxiosResponse<any, any>> {
    return axios.post(BACKEND_ADD_TRANSACTION, { to: recipient, amount, description}, { withCredentials: true })
}

export function requestTransaction(buyer: string, amount: number, description?: string): Promise<AxiosResponse<any, any>> {
    return axios.post(BACKEND_ADD_TRANSACTION_REQUEST, { from: buyer, amount, description }, { withCredentials: true })
}

export function approveTransactionRequest(requestId: number, password?: string): Promise<AxiosResponse<any, any>> {
    return axios.post(BACKEND_APPROVE_TRANSACTION_REQUEST, { requestId, password }, { withCredentials: true })
}

export function denyTransactionRequest(requestId: number): Promise<void> {
    return axios.post(BACKEND_DENY_TRANSACTION_REQUEST, { requestId }, { withCredentials: true })
}

export function listTransactions(): Promise<Transaction[] | undefined> {
    return axios.post(BACKEND_LIST_TRANSACTIONS, {}, { withCredentials: true })
        .then(response => response?.data)
}

export function listTransactionRequests(): Promise<TransactionRequest[] | undefined> {
    return axios.post(BACKEND_LIST_TRANSACTION_REQUESTS, {}, { withCredentials: true })
        .then(response => response?.data)
}