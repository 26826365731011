import axios, { AxiosResponse } from 'axios'
import { BACKEND_GET_OPTIONS, BACKEND_GET_VOTES, BACKEND_SET_VOTE } from './Const'
import { currentProfile } from './Login'

export interface VoteInfo {
    hasVoted: boolean,
    title: string,
    description: string,
    id: number,
    endTime: number
}

type VoteOptionDisplay =
  {
    type: "text",
    content: string,
    info?: string
  } | {
    type: "img",
    images: {src: string, alt: string, label?: string }[],
    info?: string
  }

export interface VoteOptions {
    display: VoteOptionDisplay[],
    results?: number[]
    choosenOptionIndex?: number
}

export function getVoteInfoList(): Promise<VoteInfo[]> {
    const profile = currentProfile
    if(!profile) return Promise.resolve([])
    
    return axios.post(BACKEND_GET_VOTES, { UserID: profile.dbuid }, { withCredentials: true })
        .then(response => response?.data)
}

export function getVoteOptions(id: number): Promise<VoteOptions> {
    const profile = currentProfile
    if(!profile) return Promise.resolve({ display: [] })

    function parseOptions(response: AxiosResponse<any, any>) {
      if(typeof(response?.data) !== "string")
        return undefined
      let options = JSON.parse(response?.data)
      if(options.choosenOptionIndex) {
        options.choosenOptionIndex--
      }
      return options
    }

    return axios.post(BACKEND_GET_OPTIONS, {VoteID: id, UserID: profile.dbuid}, { withCredentials: true })
        .then(response => parseOptions(response))
}

export function changeVote(id: number, option: number): Promise<{} | undefined> {
    const profile = currentProfile
    if(!profile) return Promise.resolve(undefined)
    return axios.post(BACKEND_SET_VOTE, {VoteID: id, UserID: profile.dbuid, OptionIndex: option}, { withCredentials: true })
}

export function getTimeSeconds() {
    return Math.floor(new Date().getTime() / 1000)
}