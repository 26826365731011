import { PropsWithChildren, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function TrustedLinkReplacer(props: PropsWithChildren<{}>) {
    const contentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()

    useEffect(() => {
        //Make "InnerLink" elements work.
        const content = contentRef.current
        if(!content) return
        for(const link of content.getElementsByTagName("InnerLink")) {
            const to = link.getAttribute('to')
            if(!to) continue
            link.addEventListener('click', () => {
                navigate(to as string)
            })
        }
    });

    return <div ref={contentRef}>{props.children}</div>
}