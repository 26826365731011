// In App.js or a similar component file
import React from "react";
import "./Voting.css";
import Collapsible from "react-collapsible";
import {
  VoteInfo,
  VoteOptions,
  changeVote,
  getTimeSeconds,
  getVoteInfoList,
  getVoteOptions,
} from "../../services/Voting";
import Header from "../../Header/Header";
import { requireLoggedIn } from "../../services/Login";
import TrustedLinkReplacer from "../TrustedLinkReplacer/TrustedLinkReplacer";

export default class Voting extends React.Component<
  {},
  { votes: SingleVoteProp[] }
> {
  componentDidMount() {
    if (!requireLoggedIn()) return;
    getVoteInfoList().then((voteInfos) => {
      if (!voteInfos) return;
      this.setState({
        votes: voteInfos.map((info) => {
          return { info };
        }),
      });
    });
  }

  render() {
    return (
      <div className="Voting">
        <Header goBackLink="/" />
        <ul>
          {this.state && this.state.votes.length === 0 && (
            <li key={0}>
              Die Abstimmungen werden vorraussichtlich am Montag dem 26.02.24 um
              6:00 Uhr freigeschaltet.
            </li>
          )}
          {this.state &&
            this.state.votes.map((vote) => {
              return (
                <li key={vote.info.title} className="Vote">
                  <SingleVote info={vote.info} options={vote.options} />
                </li>
              );
            })}
          <li>
            <span className="VotingFooter">
              Deine Abstimmungen werden direkt nach dem Auswählen gespeichert,
              du kannst aber später deine Meinung noch ändern.
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

interface SingleVoteProp {
  info: VoteInfo;
  options?: VoteOptions;
}
interface SingleVoteState extends SingleVoteProp {
  isGray: boolean;
  isUpdating: boolean;
}

class SingleVote extends React.Component<SingleVoteProp, SingleVoteState> {
  componentDidMount() {
    this.setState({
      ...this.props,
      isGray:
        this.props.info.hasVoted || this.props.info.endTime <= getTimeSeconds(),
    });
  }

  updateVoteOptions() {
    getVoteOptions(this.state.info.id)
      .then((options) => {
        if (!options) return;
        this.setState({
          ...this.state,
          isUpdating: false,
          options,
          isGray:
            options.choosenOptionIndex !== undefined ||
            this.state.info.hasVoted ||
            this.state.info.endTime <= getTimeSeconds(),
        });
      })
      .catch((err) => {
        this.setState({ ...this.state, isUpdating: false });
        return Promise.reject(err);
      });
  }

  voteForOption(option: number) {
    const previousOption = this.state?.options?.choosenOptionIndex;
    const resetVote = () => {
      this.setState({
        ...this.state,
        isUpdating: false,
        options: this.state.options && {
          ...this.state.options,
          choosenOptionIndex: previousOption,
        },
      });
    };
    const resetVoteTimeout = setTimeout(resetVote, 1000);
    changeVote(this.state.info.id, option)
      .then((response) => {
        clearTimeout(resetVoteTimeout);
        if (!response)
          this.setState({
            ...this.state,
            isUpdating: false,
            options: this.state.options && {
              ...this.state.options,
              choosenOptionIndex: previousOption,
            },
          });
        this.updateVoteOptions();
      })
      .catch((err) => {
        clearTimeout(resetVoteTimeout);
        this.setState({
          ...this.state,
          options: this.state.options && {
            ...this.state.options,
            choosenOptionIndex: previousOption,
          },
        });
        this.updateVoteOptions();
        return Promise.reject(err);
      });
    this.setState({
      ...this.state,
      isUpdating: true,
      options: this.state.options && {
        ...this.state.options,
        choosenOptionIndex: option,
      },
    });
  }

  onVoteEnded() {
    if (this.state.options) {
      setTimeout(() => {
        this.updateVoteOptions();
      }, 2000);
    } else {
      this.setState({ ...this.state, isGray: true });
    }
  }

  render() {
    const options = this.state?.options;
    const results = options?.results;
    const summedResults = results?.reduce((prev, current) => prev + current);

    return (
      this.state && (
        <Collapsible
          trigger={
            <span>
              <img
                alt=""
                src="images/dropdown.svg"
                className="VoteDropdownArrow"
              />
              <span
                className={this.state.isGray ? "VoteGray" : "VoteHighlight"}
              >
                {this.state.info.title}
                <Countdown
                  endTime={this.state.info.endTime}
                  endCallback={() => this.onVoteEnded()}
                />
              </span>
            </span>
          }
          transitionTime={100}
          onTriggerOpening={() => this.updateVoteOptions()}
        >
          <div className="VoteDescription">
            <TrustedLinkReplacer>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.info.description,
                }}
              ></div>
            </TrustedLinkReplacer>
          </div>
          <br />
          <div className="VoteOptions">
            {options &&
              options.display.map((display, index) => {
                let content;
                switch (display.type) {
                  case "text":
                    content = (
                      <span className="StringOption">{display.content}</span>
                    );
                    break;
                  case "img":
                    content = display.images.map((image) => (
                      <div key={image.label}>
                        {image.label && (
                          <span className="ImgOptionLabel">{image.label}</span>
                        )}
                        <div className="ImgOptionContainer">
                          <img
                            className="ImgOption"
                            src={image.src}
                            alt={image.alt}
                          />
                        </div>
                      </div>
                    ));
                    break;
                  default:
                    return <span key={index}></span>;
                }
                return (
                  <div className="OptionBox" key={index}>
                    {results && (
                      <div className="ResultPercent">
                        {results[index] === 0
                          ? 0
                          : ((results[index] / summedResults!) * 100).toFixed(
                              2,
                            )}
                        %
                      </div>
                    )}
                    <button
                      className={
                        options!.choosenOptionIndex === index
                          ? "ChoosenOption"
                          : "Option"
                      }
                      disabled={
                        results !== undefined ||
                        (this.state &&
                          (this.state.isUpdating ||
                            this.state.options?.choosenOptionIndex === index))
                      }
                      onClick={() => this.voteForOption(index)}
                    >
                      <div
                        className={display.info && "VoteOptionContentWithInfo"}
                      >
                        {content}
                      </div>
                      {display.info && (
                        <div className="VoteOptionInfoWrapper">
                          <span className="VoteOptionInfo">{display.info}</span>
                        </div>
                      )}
                    </button>
                  </div>
                );
              })}
          </div>
        </Collapsible>
      )
    );
  }
}

interface CountdownProps {
  endTime: number;
  endCallback: () => void;
}
interface CountdownState {
  timeLeft: number;
}
class Countdown extends React.Component<CountdownProps, CountdownState> {
  private currentCountdownTimeout?: NodeJS.Timeout;
  private hasEnded: boolean = false;

  componentDidMount() {
    this.hasEnded = this.updateTimeLeft();
  }

  componentDidUpdate(
    prevProps: Readonly<CountdownProps>,
    prevState: Readonly<CountdownState>,
    snapshot?: any,
  ) {
    if (this.currentCountdownTimeout) {
      clearTimeout(this.currentCountdownTimeout);
    }
    if (!this.state || this.state.timeLeft > 0) {
      this.currentCountdownTimeout = setTimeout(
        () => this.updateTimeLeft(),
        100,
      );
    } else if (!this.hasEnded) {
      this.hasEnded = true;
      this.props.endCallback();
    }
  }

  updateTimeLeft(): boolean {
    let timeLeft = this.props.endTime - getTimeSeconds();
    this.setState({ timeLeft });
    return timeLeft <= 0;
  }

  render() {
    if (!this.state) {
      return <div>Hä</div>;
    }
    if (this.state.timeLeft <= 0) {
      return <div className="CountdownOver">Vorbei</div>;
    }

    function formatDoubleDigitNumber(number: number): string {
      let result = Math.floor(number).toString();
      return result.length === 1 ? "0" + result : result;
    }

    let timeLeft = this.state.timeLeft;
    const seconds = formatDoubleDigitNumber(timeLeft % 60);
    const minutes = formatDoubleDigitNumber((timeLeft / 60) % 60);
    const hours = formatDoubleDigitNumber((timeLeft / 3600) % 24);
    const days = Math.floor(timeLeft / 86400);
    return (
      <div className="CountdownRunning">{`${days}d ${hours}h ${minutes}m ${seconds}s`}</div>
    );
  }
}
