import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as FetchHtml from '../FetchHtml/FetchHtml';

export function ScrollPositioner(props: {resetPaths: string[]}) {

    const location = useLocation();
    const [ scrollPositions, setScrollPositions ] = useState<{ [key: string]: [number, number] }>({});


    useEffect(() => {
        if(props.resetPaths.includes(location.pathname)) return;
        
        const handleScroll = () => {
            if(FetchHtml.fetchingComponents.size > 0) return;
            setScrollPositions({ ...scrollPositions, [location.pathname]: [window.scrollX, window.scrollY] });
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.pathname, props.resetPaths]);

    useLayoutEffect(() => {
        const scrollUpdater = () => {
            const path = location.pathname;
            if(scrollPositions[path]) {
                window.scrollTo(scrollPositions[path][0], scrollPositions[path][1]);
            } else {
                window.scrollTo(0, 0);
            }
        }
        scrollUpdater();
        window.addEventListener('fetchingComponentsEmpty', scrollUpdater);
        return () => window.removeEventListener('fetchingComponentsEmpty', scrollUpdater);
    }, [location.pathname]);

    return null;
};