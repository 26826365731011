import React from 'react';
import './LoginGuide.css';
import { Link } from 'react-router-dom';
import Header from '../../Header/Header';

export default class LoginGuide extends React.Component {
  render() {
    const email = "technik@klausmachtstaat.de"
    const emailLinkElement = <a href={"mailto:" + email}>{email}</a>
    return (
        <div className='LoginGuide'>
            <Header goBackLink='/login'/>
            <div className='LoginGuideContent'>
                <h1>Wo bekomme ich meine Logindaten?</h1>
                <p>Deine Logindaten befinden sich auf Nextcloud, welches du wie im Folgenden erklärt öffnen kannst. Falls du Probleme hast, an deine Logindaten zu kommen, mache dies gerne mit deinen Eltern oder schicke uns eine Email an {emailLinkElement}.</p>
                <p>Zuerst musst du das Schulportal öffnen. Hierfür reicht es nach "Schulportal Neumünster" zu suchen, oder du nimmst direkt diesen Link: <a href="https://portal.schule.neumuenster.de" target="_blank" rel="noopener noreferrer">portal.schule.neumuenster.de</a></p>
                <p>Auf dem Schulportal musst du anschließend Nextcloud auswählen, indem du auf den Nextcloud Knopf drückst:</p>
                <img src="/images/loginGuide/Schulportal-Nextcloud.png" alt="Die Hauptseite des Schulportals, auf welcher der  Nextcloud Knopf markiert ist"/>
                <p>Woraufhin sich eine Login Seite öffnen wird. Hier musst du die Nextcloud Logindaten eingeben, die du zuvor von der Schule erhalten hast. Falls du diese Daten nicht mehr hast oder sie nicht funktionieren, kontaktiere bitte Herrn Streicher oder sprich mit deiner Klassenlehrkraft:</p>
                <img src="/images/loginGuide/Schulportal-Login.png" alt="Die Loginseite des Schulportals" />
                <p>Nachdem du dich eingeloggt hast, öffnet sich Nextcloud. Um die Dateien auf Nextcloud zu sehen, musst du oben Links auf das Ordner-Symbol klicken:</p>
                <img src="/images/loginGuide/Nextcloud-Dashboard.png" alt="Das Nextcloud Dashboard, auf dem der Dateien-Tab    markiert ist" />
                <p>Nun siehst du eine Liste der Ordner und der Dateien, die dir zur Verfügung stehen. In dieser Liste sollte sich auch der Ordner "Schule als Staat Zugangsdaten" befinden, auf welchen du klicken musst, um ihn zu öffnen:</p>
                <img src="/images/loginGuide/Nextcloud-Credentials-Folder.png" alt="Die Nextcloud Dateien Liste, auf welcher der 'Schule als Staat Zugangsdaten' Ordner markiert ist"/>
                <p>Falls du den Ordner nicht siehst, kontaktiere uns bitte unter der Email {emailLinkElement}</p>
                <p>In diesem Ordner solltest du eine Datei mit deinem Namen sehen können, in welcher sich deine Zugangsdaten befinden. Klicke auch auf diese, um sie zu öffnen:</p>
                <img src="/images/loginGuide/Nextcloud-Credentials-File.png" alt="Der Inhalt des Zugangsdaten Ordners, in welchem die eine angezeigte Datei markiert ist"/>
                <p>In der Datei steht dein Benutzername und dein Passwort für "Schule als Staat". Mit diesen kannst du dich bei unserer <Link to="/login">Loginseite</Link> anmelden. Außerdem solltest du deine Zugangsdaten auch während des Projekts parat haben, damit du auf dein Konto zugreifen und bezahlen kannst.</p>
                <img src= "/images/loginGuide/Nextcloud-Credentials-File-Content.png" alt="Bild: Inhalt der Datei." />
            </div>
        </div>
    );
  }
}